import { useState } from 'react';
import './App.css';

interface ClickableImgProps {
    imgPath: string;
    translateX: string;
    setAnimate: (v: boolean) => void;
}

const ClickableImg = (props: ClickableImgProps) => {
    return (
        <a href="#">
            <img
                onMouseOver={(e) => props.setAnimate(false)}
                onMouseLeave={(e) => props.setAnimate(true)}
                src={props.imgPath}
                style={{
                    zIndex: 100,
                    position: 'relative',
                    width: '100px',
                    height: '100px',
                    top: 0,
                    left: 0,
                    transform: `translate(${props.translateX})`,
                    cursor: 'pointer',
                }}
            />
        </a>
    );
};

function App() {
    const [animate, setAnimate] = useState(true);

    return (
        <div className="App">
            <header className="App-header">
                <div
                    style={{
                        zIndex: 100,
                        position: 'absolute',
                        animation: 'spin infinite 10s linear',
                        animationPlayState: animate ? 'running' : 'paused',
                    }}
                >
                    <style>
                        {`@keyframes spin {
                            from {
                                transform: rotate(0deg);
                            }
                            to {
                                transform: rotate(360deg);
                            }
                        }`}
                    </style>
                    <ClickableImg imgPath="./parmesan-cheese.png" translateX="400px" setAnimate={setAnimate} />
                    <ClickableImg imgPath="./mozzarella-cheese.png" translateX="-400px" setAnimate={setAnimate} />
                </div>
                <h2>Clio Cheesus Website</h2>
                <img
                    src="./cheesus.jpg"
                    className="App-logo"
                    alt="logo"
                    style={{
                        borderRadius: '50%',
                    }}
                />
                <h2>Pray to our lord and saviour cheesus</h2>
            </header>
        </div>
    );
}

export default App;
